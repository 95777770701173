<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>运营和营销</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/extension' }"
          >推广用户</el-breadcrumb-item
        >
        <el-breadcrumb-item>新建推广等级</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="240px"
        size="mini"
      >
        <el-form-item label="名称：" label-position="left" prop="name">
          <el-input style="width: 200px" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item
          label="升级范围起始金额："
          label-position="left"
          prop="upgrade_start"
        >
          <el-input
            style="width: 200px"
            v-model="form.upgrade_start"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="升级范围终止金额："
          label-position="left"
          prop="upgrade_end"
        >
          <el-input style="width: 200px" v-model="form.upgrade_end"></el-input>
        </el-form-item>
        <el-form-item
          label="最低提现金额："
          label-position="left"
          prop="min_withdrawal_amount"
        >
          <el-input
            style="width: 200px"
            v-model="form.min_withdrawal_amount"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="分佣比例："
          label-position="left"
          prop="commission_rate"
        >
          <el-input
            style="width: 200px"
            v-model="form.commission_rate"
          ></el-input>
        </el-form-item>

        <el-form-item label="状态：" label-position="left">
          <el-radio v-model="form.status" :label="0">禁用</el-radio>
          <el-radio v-model="form.status" :label="1">启用</el-radio>
        </el-form-item>

        <el-form-item>
          <el-button
            size="small"
            type="primary"
            :loading="loading"
            @click="validateForm('form')"
            >保存</el-button
          >
          <router-link to="/goods" class="router-link"
            ><el-button style="margin-left: 10px" size="small"
              >取消</el-button
            ></router-link
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "template-add",
  data() {
    return {
      form: {
        status: 0,
      },
      loading: false,
      rules: {
        name: [{ required: true, message: "请输入等级名称", trigger: "blur" }],
        upgrade_start: [
          { required: true, message: "请输入起始金额", trigger: "blur" },
        ],
        upgrade_end: [
          { required: true, message: "请输入起始金额", trigger: "blur" },
        ],
        commission_rate: [
          { required: true, message: "请输入分佣比例", trigger: "blur" },
        ],
        min_withdrawal_amount: [
          { required: true, message: "请输入最低提现金额", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    ...mapActions("extension", ["addLevel"]),
    validateForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.add();
        }
      });
    },
    async add() {
      this.loading = true;
      const { res_info } = await this.addLevel(this.form);
      this.loading = false;
      if (res_info != "ok") return;
      this.$message.success("创建成功");
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
